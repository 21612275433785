<template>
    <div class="auction">
        <div class="main">
            <div class="main-breadcrumb p-2">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item class="cursor" :to="{ path: '/ZhaoshangList' }">招商公告</el-breadcrumb-item>
                    <el-breadcrumb-item >商品详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="main-body">
                <el-card >
                    <!-- 招商图片轮播 -->
                    <el-row :gutter="5">
                        <el-carousel :interval="4000" type="card" height="300px">
                            <el-carousel-item v-for="(item,index) in imagesList" :key="index">
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item.url"
                                    :fit="fit"></el-image>
                            </el-carousel-item>
                        </el-carousel>
                        <el-row align="middle" class="titlename">{{dataForm.goodsname}}</el-row>
                    </el-row>
                </el-card>
                <el-card >
                    <!-- 招商图片轮播 -->
                    <el-row :gutter="15">
                        <!-- <el-col :span="24" class="hidden" >商品名称：<span class="red ">{{dataForm.goodsname}}</span></el-col> -->
                    </el-row>
                     <el-row :gutter="15">
                        <el-col :span="8" class="hidden" >联系人：<span class="red">{{dataForm.contacts}}</span></el-col>
                        <el-col :span="8">所在地：<span class="red">{{dataForm.targetaddressname}}</span></el-col>
                        <el-col :span="8">分类：<span class="red">{{mytargetType(dataForm.targettypecascader)}}</span></el-col>
                    </el-row>
                    <el-row :gutter="15">
                       <el-col :span="8" >项目编号：<span class="red">{{dataForm.projectno}}</span></el-col>
                        <el-col :span="4">转让价格：<span class="red">{{dataForm.overprice}}</span></el-col>
                        <el-col :span="4">所属行业：<span class="red">{{dataForm.energytypename}}</span></el-col>
                        <el-col :span="6">发布时间：<span class="red">{{dataForm.releasetime}}</span></el-col>
                    </el-row>
                </el-card>

                    <el-card>
                        <el-tabs v-model="activeName" @tab-click="handleClick" class="box">
                            <!-- <div class="smline"></div> -->
                            <el-tab-pane label="项目概况" name="five">
                                <p v-html="dataForm.project" class="chengnuohan1" style="height:60vh;overflow-x: hidden;"></p>
                            </el-tab-pane>
                            <el-tab-pane label="附件" name="six">
                                <div style="height:60vh;overflow-x: hidden;">
                                    <el-table :data="fileList" style="width: 100%" max-height="250">
                                        <el-table-column prop="name">
                                            <template slot-scope="scope">
                                                <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-card>
                </div>
            </div>


    </div>
</template>

<script>
    import UploadService from "../services/UploadFilesService";
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    export default {
        data() {
            return {
                activeName: 'five',
                imagesList:[],
                fileAddress: '',
                fileList: [],
                dataForm: {
                    project:''
                }
            };
        },
        created() {
        },
        activated() {
           this.$set(this.dataForm,'id',this.$route.query.id)
            this.getByid()
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },

        methods: {
            getByid(){
                this.$post('/shoppingui/memberEntShopping/ecCommoditiesInfoByid', this.dataForm).then((data) => {
                        if (data.code !== 0) {
                            this.$message({
                                message: '获取商品失败',
                                offset: 130,
                                type: "error",
                                showClose: "true",
                            });
                            return false;
                        }
                        this.dataForm = {
                            ...data.data
                        }
                        this.dataForm.project = data.data.project.replaceAll('http://123.56.177.143:18888/api/businessapi/','https://www.cnassets.com/api/shoppingui/')
                        if (data.data.thumb.length > 0) {
                            this.imagesList = []
                            data.data.thumb.forEach(i => {
                                this.imagesList.push({'url': this.$ImgUrl + i})
                            })
                        }
                        this.fileAddress = data.data.relevant
                    this.handleClick66()
                }).catch(() => {
                })
            },
            // 切换竞价商品明细
            handleClick(tab, event) {
                if (tab.name == 'six') {
                    if (this.fileAddress) {
                        UploadService.getFilesa1(this.fileAddress).then((response) => {
                            this.fileList = response.data

                        });
                    }
                }
            },
            // 切换竞价商品明细
            handleClick66() {
                    if (this.fileAddress) {
                        UploadService.getFilesa1(this.fileAddress).then((response) => {
                            this.fileList = response.data
                        });

                }
            },
            myurl(url) {
                if (url != null) {
                    var reg = RegExp(/shoppingui/);
                    if (reg.test(url)) {
                        const index = url.indexOf("shoppingui")
                        const res = this.$urldown + url.substring(index)
                        return res
                    }
                } else {
                    return null
                }
            },
            mytargetType(e){
                if(e!=null){
                    if(e.substr(0,2)=='11'){
                    return '产权机构'
                    }else if(e.substr(0,2)=='22'){
                    return '自行处理'
                    }else if(e.substr(0,2)=='33'){
                    return '资产租赁'
                    }else if(e.includes('a')){
                    return '产权转让'
                    }else if(e.includes('b')){
                    return '资产处置'
                    }else {
                    return '其他'
                    }
                }
                },
            myPic2(e){
                if(e.length >0){
                return true
            }
            return false
            },

        },
    };
</script>
<style scoped lang="scss">
    @import "../assets/css/index.css";
.red{
    color:red;
}
.titlename{
    text-align:center;color:red;font-size:20px;font-weight:500;
}
.hidden{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 20px;
    // background: bisque;
}
/deep/ .el-card{
    margin-bottom: 10px;
}
.cursor{
    cursor: pointer;
}
</style>
